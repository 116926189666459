<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="align-center-flex">
        <div class="d-flex">
          <input
            v-model="searchResult"
            placeholder="Search"
            class="mr-right form-control input-sm"
          />
          <select
            name=""
            id=""
            class="form-control"
            v-model="selectasset"
            style="max-width: 200px"
            @change="searchasset"
          >
            <option value="" selected >Select asset category</option>
            <option
              :value="asset._id"
              v-for="asset in newUserOptionEdit"
              :key="asset._id"
            >
              {{ asset.name }}
            </option>
          </select>
          <!-- <v-select @search="getSearch" v-model="searchSelectedData" placeholder="Search Asset"
                        class="width-20" label="text" :options="searchSelectedOptions" /> -->
        </div>

        <div>
          <button
            v-b-toggle.sidebar-right
            @click="openSlideBar()"
            class="btn pointer btn-outline-primary btn-sm margin-right"
            :disabled="!this.$store.state.auth.permissions.includes('vulnerability-update')"
          >
            + Add
          </button>
        </div>
      </div>
    </div>
    <b-card>
      <div class="user-select">
        <div class="card-company-table card card-congratulation-medal">
          <div class="mb-0 table-responsive border rounded">
            <table role="table" class="table b-table table-hover">
              <thead role="rowgroup header">
                <tr role="row">
                  <th role="columnheader" scope="col">#</th>
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Value</th>
                  <th role="columnheader" scope="col" class="text-align-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="rowgroup">
                <tr
                  v-for="(data, index) in allVulnerabilities"
                  :key="data._id"
                  role="row"
                  class="custom-table-row"
                >
                <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                  <td role="cell">{{ data.name }}</td>
                  <td role="cell">
                    <span
                      :class="[
                        {
                          'badge-danger': data.vulnerability_value == 5,
                        },
                        { 'badge-danger': data.vulnerability_value == 4 },
                        {
                          'badge-warning': data.vulnerability_value == 1,
                        },
                        { 'badge-warning': data.vulnerability_value == 2 },
                        { 'badge-primary': data.vulnerability_value == 3 },
                        'badge-box',
                      ]"
                      v-if="data.vulnerability_value"
                    >
                      {{
                        data.vulnerability_value == 1
                          ? "Very Low"
                          : "" || data.vulnerability_value == 2
                          ? " Low"
                          : "" || data.vulnerability_value == 3
                          ? "Medium"
                          : "" || data.vulnerability_value == 4
                          ? "High"
                          : "" || data.vulnerability_value == 5
                          ? "Very High"
                          : ""
                      }}
                    </span>
                  </td>
                  <td role="cell" class="text-align-right">
                    <span
                      @click="detailVulnerabilities(data._id)"
                      class="icon-box-new-new pointer"
                    >
                      <feather-icon class="rotate" icon="EyeIcon" size="20" />
                    </span>
                    <span
                      @click="showModal(data._id)"
                      class="icon-box-new-new pointer"
                    >
                      <feather-icon
                        class="rotate delete-color"
                        icon="Trash2Icon"
                        size="20"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-body">
              <b-modal
                id="modal-danger"
                ref="modal-danger"
                ok-only
                ok-variant="danger"
                @ok="deleteVulnerabilities()"
                modal-class="modal-danger"
                centered
                title="Delete !"
                :ok-disabled="
                  !this.$store.state.auth.permissions.includes(
                    'vulnerability-delete'
                  )
                "
              >
                <b-card-text>
                  Are You Sure to Delete the Vulnerability ?
                </b-card-text>

                <!-- <button @click="deleteUser(item._id)"></button> -->
              </b-modal>
            </div>
          </div>
        </div>
        <div class="float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          >
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BCard,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    allVulnerabilities: [],
    allAssets: [],
    // userOption: [],
    newUserOption: [],
    autoData: [],
    vulnerabilitiesDescription: "",
    vulnerabilitiesName: "",
    addVulnerabilitiesData: false,
    editVulnerabilitiesData: false,
    editVulnerabilitiesID: "",
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    searchResultAsset: "",
    selectedAsset: [],
    dir: "ltr",
    seletedID: [],
    searchSelectedData: [],
    searchSelectedOptions: [],
    selectedAssetEdit: [],
    newUserOptionEdit: [],
    selectedAssetEditnew: [],
    selectedAssetEditOptionNew: [],
    deleteVulnerabilitiyID: "",
    option: [
      { title: "Very Low", value: 1 },
      { title: "Low", value: 2 },
      { title: "Medium", value: 3 },
      { title: "High", value: 4 },
      { title: "Very High", value: 5 },
    ],
    allAssets: [],
    selectasset: "",
    // selected
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BPagination,
    vSelect,
    BCard,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {},
  watch: {
    currentPage(newPage) {
      this.getVulnerabilities(newPage);
    },
    searchResult(value) {
      this.getSearchByName(this.currentPage);
    },
    searchSelectedData(value) {
      this.getVulnerabilitiesSearchData(value.value, this.currentPage);
    },
  },
  mounted() {
    this.getVulnerabilities(this.currentPage);
    this.getAssets();
  },
  methods: {
    searchasset() {
      this.getSearchByName(this.currentPage);
    },
    // getassets() {
    //   const options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
    //     params: { page: num },
    //   };
    //   this.$http(options)
    //     .then((res) => {
    //       this.allAssets = res.data.data.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    showModal(itemid) {
      this.deleteVulnerabilitiyID = itemid;
      this.$refs["modal-danger"].show();
    },
    getVulnerabilitiesSearchData(id, num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/vulnerabilities?asset_category_id=${id}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilities = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getSearchByName(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/vulnerabilities?name=${this.searchResult}&asset_category_id=${this.selectasset}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilities = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    detailVulnerabilities(id) {
      this.$router.push({ name: "detailVulnerabilities", params: { id: id } });
      // this.$root.$emit('VulnerabilitiesDetail', data)
    },

    getAssets() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
      };
      this.$http(options)
        .then((res) => {
          // this.userOption = res.data.data.data
          this.newUserOptionEdit = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },
    getSearch(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/ast_category/search?name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.searchSelectedData = [];
          this.searchSelectedOptions = [];
          this.autoData = res.data.data;
          for (let [key, value] of Object.entries(this.autoData)) {
            let a = {
              value: value._id,
              text: value.name,
            };
            this.searchSelectedOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancel() {
      this.hideSideBar = false;
      this.vulnerabilitiesName = "";
      this.vulnerabilitiesDescription = "";
      this.selectedAsset = [];
    },
    openSlideBar() {
      this.$router.push({ name: "addvulnerabilities" });
    },
    getVulnerabilities(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilities = res.data.data.data;

            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteVulnerabilities(assetID) {
      assetID = this.deleteVulnerabilitiyID;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities/${assetID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getVulnerabilities();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editVulnerabilities(asset) {
      this.$router.push({
        name: "Vulnerabilitiesedit",
        params: { id: asset._id },
      });
    },
    addVulnerabilities() {
      const data = this.selectedAssetEdit;
      const val = [];
      data.forEach((ele) => {
        val.push(ele.value);
      });
      const body = {
        name: this.vulnerabilitiesName,
        description: this.vulnerabilitiesDescription,
        asset_category_id: val,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
      };
      this.$http(options)
        .then((res) => {
          this.getVulnerabilities();
          //  val = []
          this.hideSideBar = false;
          this.duplicateName = false;
          this.vulnerabilitiesName = "";
          this.vulnerabilitiesDescription = "";
          this.selectedAsset = [];
        })
        .catch((err) => {
          console.log(err);
        });
      //    }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mr-right {
  margin-right: 10px;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
.delete-color {
  color: #ea5455 !important;
}
// .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
// .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
//   background-color: #f3f2f7 !important;
//   color: #6e6b7b;
// }
.align-right {
  display: flex;
  justify-content: flex-end;
}
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
.full-width {
  width: 100%;
}
.icon-box-new-new {
  width: 25px;
  height: 25px;
  //   background-color: #7367f0;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.mr-bottom {
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #f6f6f9;
  cursor: pointer;
}
.text-align-right {
  text-align: right;
}
.width-20 {
  width: 20%;
}
.flex {
  display: flex;
}
.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
.badge-box {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
.user-select {
  user-select: none;
}
th {
  background: #472183 !important;
  color: #fff;
}
</style>
